import styled from 'styled-components';

const PageWrapper = styled.div`
	position: relative;
	text-align: center;
	.cta-info {
		color: #FFF;
		margin: 10rem auto;
		text-align: center;
	}
	p {
		font-size: 1.8rem;
		margin-top: 3rem;
	}
	.button {
		background-color: #D30046;
		border: 1px solid rgb(23, 146, 230);
		color: #FFF;
		cursor: pointer;
		display: inline-block;
		filter: brightness(1);
		font-size: 2.2rem;
		font-weight: bold;
		letter-spacing: 1.3px;
		margin-top: 5rem;
		padding: 3rem 5rem;
		text-decoration: none;
		text-transform: uppercase;
		transition: filter 0.2s ease-out;
		&:hover {
			filter: brightness(1.2);
		}
	}
`;

export default PageWrapper;