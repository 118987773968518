import React, { Component } from 'react';
import { Link } from 'gatsby';
import PageWrapper from '../styles/index.css.js';
import HomeHeader from '../components/Home/HomeHeader/HomeHeader'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <PageWrapper>
        <HomeHeader rotate={false} headerImage="1" />
        <div className="cta-info">
          <div className="underline-header">Join Us</div>
          <p>Learn more about Careers at Red Beard Games</p>
          <Link className="button" to="/careers">Explore Careers</Link>
        </div>
      </PageWrapper>
    );
  };
}

export default Home;
